import React, { Suspense, useCallback } from "react";
import { Route, Routes } from "react-router-dom";
import { pagePath } from "./pagePath";
import { navlinksBundle } from "../layouts/navbar/navLinksBundle";
import Loader from "../layouts/loader/loader";

// components
const Navbar = React.lazy(() => import("../layouts/navbar/navbar"));
const Authenticator = React.lazy(() =>
  import("../components/authenticator/authenticator")
);

// ########## pages
//############## admin

const AdminLogin = React.lazy(() => import("../pages/admin/login/login"));
// ################### courses
const ManageCourse = React.lazy(() =>
  import("../pages/admin/manageCourse/manageCourse")
);
const CreateCourse = React.lazy(() =>
  import("../pages/admin/createCourse/createCourse")
);
const ViewCourse = React.lazy(() =>
  import("../pages/admin/viewCourse/viewCourse")
);
// ################### institution
const ManageInstitution = React.lazy(() =>
  import("../pages/admin/manageInstitution/manageInstitution")
);
const ViewInstitution = React.lazy(() =>
  import("../pages/admin/viewInstitution/viewInstitution")
);

//############## user
const UserLogin = React.lazy(() => import("../pages/user/login/login"));
const UserProfile = React.lazy(() => import("../pages/user/profile/profile"));

const UserHome = React.lazy(() => import("../pages/user/home/home"));

const UserViewCourse = React.lazy(() =>
  import("../pages/user/viewCourse/viewCourse")
);
const UserViewCourseUnit = React.lazy(() =>
  import("../pages/user/viewCourseUnit/viewCourseUnit")
);

const UserViewCourseModule = React.lazy(() =>
  import("../pages/user/viewCourseModule/viewCourseModule")
);

const UserViewCourseModuleTest = React.lazy(() =>
  import("../pages/user/viewCourseModuleTest/viewCourseModuleTest")
);
const UserPlayground = React.lazy(() =>
  import("../pages/user/playground/playground")
);

//############## Institution
const InstitutionLogin = React.lazy(() => import("./institution/login/login"));

//############## Landing page
const LandingPage = React.lazy(() => import("./landing/landing"));

const Pages = () => {
  return (
    <Routes>
      {/* ######################################################## */}
      {/* Admin Routes */}
      <Route
        path={pagePath.admin_login}
        element={
          <Suspense fallback={<Loader loading={true} />}>
            <AdminLogin />
          </Suspense>
        }
      />

      <Route
        path={pagePath.admin}
        element={
          <Authenticator
            role="admin"
            Component={<Navbar role="admin" bundle={navlinksBundle.admin} />}
          />
        }
      >
        {/* ################### course */}
        <Route
          path={pagePath.admin_manage_course}
          element={<Authenticator role="admin" Component={<ManageCourse />} />}
        />
        <Route
          path={pagePath.admin_new_course}
          element={<Authenticator role="admin" Component={<CreateCourse />} />}
        />
        <Route
          path={pagePath.admin_view_course}
          element={<Authenticator role="admin" Component={<ViewCourse />} />}
        />

        {/* ################### institution */}
        <Route
          path={pagePath.admin_manage_institution}
          element={
            <Authenticator role="admin" Component={<ManageInstitution />} />
          }
        />
        <Route
          path={pagePath.admin_view_institution}
          element={
            <Authenticator
              role="admin"
              Component={<ViewInstitution role="admin" />}
            />
          }
        />
      </Route>

      {/* ######################################################## */}
      {/* Institution Routes */}

      <Route
        path={pagePath.institution_login}
        element={
          <Suspense fallback={<Loader loading={true} />}>
            <InstitutionLogin />
          </Suspense>
        }
      />
      <Route
        path={pagePath.institution}
        element={
          <Authenticator
            role="admin"
            Component={
              <Navbar role="institution" bundle={navlinksBundle.institution} />
            }
          />
        }
      >
        <Route
          path={pagePath.institution_home}
          element={
            <Authenticator
              role="institution"
              Component={<ViewInstitution breadcrumb={false} />}
            />
          }
        />
      </Route>

      {/* ######################################################## */}
      {/* User Routes */}
      <Route
        path={pagePath.user_login}
        element={
          <Suspense fallback={<Loader loading={true} />}>
            <UserLogin />
          </Suspense>
        }
      />
      <Route
        path={pagePath.user_profile}
        element={
          <Suspense fallback={<Loader loading={true} />}>
            <UserProfile />
          </Suspense>
        }
      />
      <Route
        path={pagePath.user}
        element={
          <Authenticator role="user" Component={<Navbar role="user" />} />
        }
      >
        <Route
          path={pagePath.user_home}
          element={
            // <Authenticator role="user">
            <UserHome />
            // </Authenticator>
          }
        />
        <Route
          path={"/user/playground"}
          element={
            // <Authenticator role="user">
            <UserPlayground />
            //  </Authenticator>
          }
        />
        <Route
          path={pagePath.user_course}
          element={
            // <Authenticator role="user">
            <UserViewCourse />
            // </Authenticator>
          }
        />
        <Route
          path={pagePath.user_course_unit}
          element={
            // <Authenticator role="user">
            <UserViewCourseUnit />
            // </Authenticator>
          }
        />
        <Route
          path={pagePath.user_course_module}
          element={
            // <Authenticator role="user">
            <UserViewCourseModule />
            // </Authenticator>
          }
        />
        <Route
          path={pagePath.user_course_module_test}
          element={
            // <Authenticator role="user">
            <UserViewCourseModuleTest />
            // </Authenticator>
          }
        />
      </Route>
      <Route index element={<LandingPage />} />
    </Routes>
  );
};

export default Pages;
